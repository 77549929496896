import { Box, List, ListItem, ListItemText, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { isLoggedIn, refreshToken } from '../../redux/features/auth/authService';
import SignInDialog from '../../components/common/SignInDialog';
import axios from 'axios';
import { fetchUserProfile, refreshAccessToken } from '../../redux/features/auth/authSlice';
import SubscriptionsCard from '../../components/common/cards/SubscriptionsCard';
import OrganisationCard from '../../components/common/cards/OrganisationCard';
import OrganisationAdminCard from '../../components/common/cards/OrganisationAdminCard';
import OrganisationUsersCard from '../../components/common/cards/OrganisationUsersCard';
import OrganisationsPage from './OrganisationsPage';
import ProfilePage from '../account/MyProfilePage';
import SubscriptionsPage from './SubscriptionsPage';

const loginpage = "/login"

axios.defaults.baseURL = window.env.API_URL;
type Props = {}


const AdminPage = (props: Props) => {
const [selectedSection, setSelectedSection] = useState<'my_profile' | 'organisations' | 'subscriptions'>('my_profile');

const navigate = useNavigate()
const authData = useSelector((state: RootState) => state.auth);

// AUTHENTICATION CHECK
const dispatch = useDispatch<AppDispatch>();
useEffect(() => {
  if (!authData.isAuthenticated) {
      navigate(loginpage);
      return;
  }
  if (!isLoggedIn(authData.access)) {
      dispatch(refreshAccessToken());
  }
  console.log(authData)
}, [dispatch, navigate, authData.isAuthenticated]);



return authData.isAuthenticated == false ? (
<SignInDialog isAuthenticated={authData.isAuthenticated} />
) : authData && authData.isAuthenticated == true ? (
  
<Box sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
        <Box sx={{ width: '10em', borderRight: '1px solid #ddd', borderTop: '1px solid #ddd', borderTopRightRadius: 3 }}>
          <List sx={{m:0, p:0}}>
            <ListItem button selected={selectedSection === 'my_profile'} onClick={() => setSelectedSection('my_profile')}>
              <ListItemText primary="My Profile" />
            </ListItem>
            <ListItem button selected={selectedSection === 'organisations'} onClick={() => setSelectedSection('organisations')}>
              <ListItemText primary="Organisations" />
            </ListItem>
            <ListItem button selected={selectedSection === 'subscriptions'} onClick={() => setSelectedSection('subscriptions')}>
              <ListItemText primary="Subscriptions" />
            </ListItem>
          </List>
        </Box>

        <Grid 
          container 
          sx={{ 
            maxWidth: '100vw', 
            paddingX: 2, 
            pt: 0.5, 
            pb: 2, 
            overflowY: 'auto',
            '&::-webkit-scrollbar': { display: 'none' },
            '-ms-overflow-style': 'none', // Internet Explorer 10+
            'scrollbar-width': 'none', // Firefox
          }}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {selectedSection === 'my_profile' && <ProfilePage />}
          {selectedSection === 'organisations' && <OrganisationsPage />}
          {selectedSection === 'subscriptions' && <SubscriptionsPage />}
        </Grid>
      </Box>
) : null;
}

export default AdminPage;

