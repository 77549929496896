import { Box, Button } from '@mui/material';
import versionConfig from '../../configs/versionConfig';
import dayjs from 'dayjs';

const Footer = () => {
  
  const currentYear = dayjs().year();

  return (
    <Box
      component="footer"
      sx={{
        maxHeight: "45px",
        minHeight: "45px",
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#333',
        paddingX: '6vw',
        alignItems: 'center',
        color: "#fff",
        zIndex: 100
      }}
    >
    <Box></Box>
    <Box>{currentYear} NovarumDX®</Box>
    <Box>{versionConfig.version}</Box>
    </Box>
  );
};

export default Footer;
