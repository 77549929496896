import { Outlet } from "react-router-dom";
import { Box, styled, Toolbar } from "@mui/material";
import sizeConfigs from "../../configs/sizeConfigs";

import colorConfigs from "../../configs/colorConfigs";
import React from "react";
import Topbar from "../common/Topbar";
import Footer from "../common/Footer";


const MainLayout = () => {
  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      m: 0,
      height: "100vh", 
      width: "100vw",
      overflowY: 'auto',
      '&::-webkit-scrollbar': { display: 'none' },
      '-ms-overflow-style': 'none', // Internet Explorer 10+
      'scrollbar-width': 'none', // Firefox
      p: 0
      }}
      >
      <Topbar />
      <Box
        component="main"
        sx={{
          paddingTop: '60px', // Header Height
          paddingBottom: '45px', // Footer Height
          flexGrow: 1,

        }}
      > 
        <Outlet/>
      </Box>
      <Footer />
    </Box>
  );
};

export default MainLayout;