import { Alert, Box, AlertTitle, Snackbar, Button, Card, Divider, FormControlLabel, FormGroup, Grid, IconButton, Link, Paper, Switch, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { isLoggedIn, refreshToken } from '../../../redux/features/auth/authService';
import axios from 'axios';
import { fetchUserProfile, refreshAccessToken } from '../../../redux/features/auth/authSlice';
import { Field, Form, Formik, FormikProvider, useFormik } from 'formik';
import { FormIsActiveSwitch, OrgIDTextField, FormUserFirstNameTextField, FormUserIDTextField, FormUserLastNameTextField, OrgNameTextField, OrgTierTextField } from '../../../components/common/fields/ProfileFeilds';
import * as Yup from "yup";
import EditIcon from '@mui/icons-material/Edit';
import MuiAlert from '@mui/material/Alert';
import { SyntheticEvent } from 'react';
import { SnackbarCloseReason } from '@mui/material';

const loginpage = "/login"

axios.defaults.baseURL = window.env.API_URL;
type Props = {}


const OrganisationCard = (props: Props) => {
const [open, setOpen] = useState(false);

const [allowEdit, setAllowEdit] = useState<boolean>(false)
const [errors, setErrors] = useState<any>(null)
const navigate = useNavigate()
const authData = useSelector((state: RootState) => state.auth);

// Handles SnackBar Alert
const handleClick = () => {
    setOpen(true);
  };
const handleSnackbarClose = (event: Event | SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
if (reason === 'clickaway') {
    return;
}
setOpen(false);
};
const handleAlertClose = (event: SyntheticEvent<Element, Event>) => {
setOpen(false);
};

// AUTHENTICATION CHECK
const dispatch = useDispatch<AppDispatch>();
useEffect(() => {
  if (!authData.isAuthenticated) {
      navigate(loginpage);
      return;
  }
  if (!isLoggedIn(authData.access)) {
      dispatch(refreshAccessToken());
  }
  console.log(authData)
}, [dispatch, navigate, authData.isAuthenticated]);

const fetchData = () => {
    let url = `organisations/`
    axios.get(url, { 
      headers: {
      'Authorization': `Bearer ${authData.access}`
    }
  })
    .then(res => {
      // Set Data
      console.log(res.data.results[0])
      const org = res.data.results[0]
      formik.setFieldValue('id', org.id);
      formik.setFieldValue('subscription_tier', org.subscription_tier);
      formik.setFieldValue('name', org.name);
      formik.setFieldValue('is_active', org.is_active);
    })
    .catch(err => {
      console.log(err)
    })
  }

useEffect(() => {
    fetchData()
},[])

const validationSchema = () => {
    return Yup.object().shape({
        name: Yup.string().required("This field is required"), 
    });
}

const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
        id: 0,
        subscription_tier: "",
        name: "",
        is_active: false
    },
    validationSchema,
    onSubmit: async (values) => {
        handleClick()
        const patch_data = {
                name: values.name,
            }

        try {
            await axios.patch(`organisations/${formik.values.id}/`, patch_data, {
                headers: {
                    'Authorization': `Bearer ${authData.access}`
                }
            })
            setErrors(null);
            setAllowEdit(!allowEdit)
            console.log(`Organisation name edited successfully.`);
        } catch (error: any) {
            if (error?.response?.status === 400) {
                const firstErrorItem = Object.keys(error.response.data)[0];
                setErrors(`${firstErrorItem}: ${error.response.data[firstErrorItem]}`);
            } else {
                const errorText = `Unknown error 'updating' organisation name`;
                setErrors(errorText);
                console.error(errorText, error);
            }
            // window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        dispatch(refreshAccessToken());
    },
    });


    return (
        <Paper variant="outlined" sx={{ height: '100%', padding: 2, boxShadow: 2 }}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" gutterBottom>
                    My Organisation
                </Typography>
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div>
                            {errors && (
                                <Alert severity="error" style={{ marginTop: '16px' }}>
                                    <AlertTitle>Error</AlertTitle>
                                    {errors}
                                </Alert>
                            )}
                            <div className="form-group">
                                <div style={{ display: 'flex', gap: '16px' }}>
                                    <Field name="id" type="text" allowEdit={false} disabled={allowEdit} className="form-control" component={OrgIDTextField} />
                                    <Field name="subscription_tier" type="text" allowEdit={false} disabled={allowEdit} className="form-control" component={OrgTierTextField} />
                                </div>
                                <Field name="name" type="text" allowEdit={allowEdit} className="form-control" component={OrgNameTextField} />
                                {/* <Field name="is_active" type="checkbox" allowEdit={false} component={FormIsActiveSwitch} /> */}
                            </div>
                        </div>
                        {!allowEdit ? 
                        <div className="form-group" style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginTop: '10px' }}>
                            <Button variant="contained">Upgrade Plan</Button>
                            <IconButton onClick={() => setAllowEdit(!allowEdit)} aria-label="edit">
                                <EditIcon />
                            </IconButton>
                        </div> :
                        <div>
                            <Divider variant="middle" flexItem sx={{ paddingTop: "10px" }} />
                            <div className="form-group" style={{ display: "flex", justifyContent: "space-between", paddingTop: "10px" }}>
                                <Button onClick={() => { setAllowEdit(!allowEdit) }} variant="outlined">Cancel Changes</Button>
                                <Button type="submit" variant="outlined">Save</Button>
                            </div>
                        </div>
                        }
                    </form>
                </FormikProvider>
            </Box>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
                    Organisation name successfully updated
                </MuiAlert>
            </Snackbar>
        </Paper>
    )
};

export default OrganisationCard;

