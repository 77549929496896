import { Box, List, ListItem, ListItemText, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { isLoggedIn, refreshToken } from '../../redux/features/auth/authService';
import SignInDialog from '../../components/common/SignInDialog';
import axios from 'axios';
import { fetchUserProfile, refreshAccessToken } from '../../redux/features/auth/authSlice';
import SubscriptionsCard from '../../components/common/cards/SubscriptionsCard';
import OrganisationCard from '../../components/common/cards/OrganisationCard';
import OrganisationAdminCard from '../../components/common/cards/OrganisationAdminCard';
import OrganisationUsersCard from '../../components/common/cards/OrganisationUsersCard';
import UsersPage from '../users/UsersPage';
import OAUsersCard from '../../components/common/cards/OAUsersCard';

const loginpage = "/login"

axios.defaults.baseURL = window.env.API_URL;
type Props = {}


const AccountPage = (props: Props) => {
const [selectedSection, setSelectedSection] = useState<'subscription' | 'account' | 'users'>('subscription');

const navigate = useNavigate()
const authData = useSelector((state: RootState) => state.auth);

// AUTHENTICATION CHECK
const dispatch = useDispatch<AppDispatch>();
useEffect(() => {
  if (!authData.isAuthenticated) {
      navigate(loginpage);
      return;
  }
  if (!isLoggedIn(authData.access)) {
      dispatch(refreshAccessToken());
  }
  console.log(authData)
}, [dispatch, navigate, authData.isAuthenticated]);



return authData.isAuthenticated == false ? (
<SignInDialog isAuthenticated={authData.isAuthenticated} />
) : authData && authData.isAuthenticated == true ? (
<Box sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
        <Box sx={{ width: '10em', maxWidth: '10em', minWidth: '10em', borderRight: '1px solid #ddd', borderTop: '1px solid #ddd', borderTopRightRadius: 3 }}>
          <List sx={{m:0, p:0}}>
            <ListItem button selected={selectedSection === 'account'} onClick={() => setSelectedSection('account')}>
              <ListItemText primary="My Account" />
            </ListItem>
            <ListItem button selected={selectedSection === 'subscription'} onClick={() => setSelectedSection('subscription')}>
              <ListItemText primary="Subscription" />
            </ListItem>
            {!authData.isAdmin &&
            <ListItem button selected={selectedSection === 'users'} onClick={() => setSelectedSection('users')}>
              <ListItemText primary="Users" />
            </ListItem>
            }
          </List>
        </Box>

        <Grid 
          container 
          sx={{ maxWidth: '100vw', paddingX: 2, pb: 2, overflowY: "auto" }}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {selectedSection === 'account' && (
            <Box sx={{ hiehgt: '60vh', width: '100%', display: "flex", justifyContent: "space-between", gap: 2}}>
                <Box sx={{width: "50%", minHeight: '100%'}}><OrganisationCard /></Box>
                <Box sx={{width: "50%", minHeight: '100%'}}><OrganisationAdminCard /></Box>
            </Box>          
            )
            }
          {selectedSection === 'subscription' && <SubscriptionsCard />  }
          {selectedSection === 'users' && <OAUsersCard />}
        </Grid>
      </Box>
) : null;
}

export default AccountPage;

