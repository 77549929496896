
import { AppBar, Backdrop, Box, Grid, IconButton, Menu, MenuItem, SpeedDial, SpeedDialAction, SpeedDialIcon, styled, Tab, Tabs, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import assets from "../../assets";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../redux/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation } from 'react-router-dom';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);


const Topbar = () => {
  const authData = useSelector((state: RootState) => state.auth);

  const pathToTabIndex: Record<string, number>  = 
    authData.isAdmin ? {
        '/': 0,
        '/results': 1,
        '/feedback': 2,
        '/users': 3,
        '/admin': 4,
        '/profile': 5
      } : {
        '/': 0,
        '/results': 1,
        '/feedback': 2,
        '/account': 3,
        '/profile': 4,
      }

  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState<any>(pathToTabIndex[location.pathname] ?? 0);
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const actions = [
    { icon: <LogoutIcon />, name: 'Logout' },
    { icon: <AccountBoxIcon />, name: 'Profile' },
    { icon: <Typography sx={{display: "flex", justifyContent:"baseline", alignItems: "center", width: "fit-contents"}}>{authData?.user?.first_name} {authData?.user?.last_name}</Typography>, name: 'Me' },
  ];

  useEffect(() => {
    const newValue = pathToTabIndex[location.pathname];
    if (newValue !== undefined) {
      setValue(newValue);
    }
  }, [location.pathname]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    if(authData.isAdmin) {
      switch (newValue) {
        case 0:
          navigate('/');
          break;
        case 1:
          navigate('/results');
          break;
        case 2:
          navigate('/feedback');
          break;
        case 3:
          navigate('/users');
          break;
        case 4:
          navigate('/admin');
          break;
        case 5:
          navigate('/admin');
          break;
        case 6:
          dispatch(logout());
          navigate('/login');
          break;
        default:
          navigate('/');
      }
    }
    if(authData.isOrganisationAdmin) {
      switch (newValue) {
        case 0:
          navigate('/');
          break;
        case 1:
          navigate('/results');
          break;
        case 2:
          navigate('/feedback');
          break;
        case 3:
          navigate('/account');
          break;
        case 4:
          navigate('/account');
          break;
        case 5:
          dispatch(logout());
          navigate('/login');
          break;
        default:
          navigate('/');
      }
    }
  };

  return (
    <AppBar position="fixed" sx={{zIndex: 100, paddingX: "5vw", height: "60px", backgroundColor: 'rgba(0, 0, 0, 0.05)', display: 'flex', justifyContent: 'center' }}>

    <Box>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={3}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: "center" }}>
            <Link to="/" onClick={() => setValue(0)} style={{display: 'flex', alignItems: 'center'}}>
              <img src={assets.images.novarumLogo} alt="logo" style={{ height: 'auto', maxWidth: '80%' }} />
            </Link>
          </Box>
        </Grid>
        <Grid item sm={8} md={8} sx={{ display: "flex", justifyContent:"center", width: '100%',}}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="navigation tabs"
            variant="scrollable"  // Make tabs scrollable on small screens
            scrollButtons="auto"  // Show scroll buttons if needed
            sx={{
              width: '100%',
              '& .MuiTab-root': {
                textTransform: 'none',
                fontWeight: 'bold',
                height: { sm: '20%', md: 'inherit' },
                width: { sm: '20%', md: 'inherit' },
                fontSize: { sm: '0.75rem', md: '1rem' },  // Responsive font size
              },
            }}
          >
            <Tab label="Dashboard" disableRipple sx={{ml: { sm: 0, md: '5%' }}}/>
            <Tab label="Results" disableRipple sx={{ml: { sm: 0, md: '5%' }}}/>
            <Tab label="Feedback" disableRipple sx={{ml: { sm: 0, md: '5%' }}}/>
            {authData.isAdmin && 
            <Tab label="Users" disableRipple sx={{ml: { sm: 0, md: '5%' }}}/>
            }
            {authData.isAdmin && 
            <Tab label="Admin Panel" disableRipple sx={{ml: { sm: 0, md: '5%' }}}/>
            }
            {authData.isOrganisationAdmin && 
            <Tab label="Account" disableRipple sx={{ml: { sm: 0, md: '5%' }}}/>
            }
            </Tabs>
        </Grid>
        <Grid item sm={1} md={1} sx={{ display: 'flex', justifyContent: "flex-end"}}>
          <Backdrop open={open} />
          <SpeedDial
              ariaLabel="SpeedDial tooltip"
              sx={{
                position: 'fixed',  // Ensure it's positioned properly relative to the screen
                top: 2,  // Adjust to your preference to prevent overlapping with the navbar
                right: "4.2vw",   // Adjust to your preference
                '& .MuiFab-primary': {
                  width: '45px',   // Ensure the SpeedDial icon remains circular (typical size for Fab)
                  height: '45px',  // Ensure the height matches the width
                  borderRadius: '50%', // Keep the main icon circular
                },
                '& .MuiSpeedDial-actions': {
                  '& .MuiSpeedDialAction-fab': {
                  }
                }
              }}         
              icon={<AccountBoxIcon />}
              onClose={handleClose}
              onOpen={handleOpen}
              open={open}
              direction='left'
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name} // Display the action name as a tooltip
                  tooltipPlacement="bottom"
                  FabProps={{
                    sx: {
                      width: action.name === "Me" ? "auto" : "40px", // Auto width for "Me" and fixed width for others
                      minWidth: action.name === "Me" ? "fit-content" : "40px", // Ensure minimum width fits content
                      maxWidth: "200px", // Optional: Set a maximum width to handle very long names
                      padding: action.name === "Me" ? "0 12px" : "0", // Add padding for dynamic width
                      borderRadius: 2,
                      justifyContent: action.name === "Me" ? 'flex-start' : 'center', // Align content properly
                    },
                  }}
                  onClick={() => {
                    if (action.name === 'Profile' || action.name === 'Me') {
                      navigate('/profile');
                      handleClose();
                    } else if (action.name === 'Logout') {
                      dispatch(logout());
                      navigate('/login');
                      handleClose();
                    }
                  }}
                />
              ))}
            </SpeedDial>
        </Grid>
      </Grid>
    </Box>
    </AppBar>
  );
};

export default Topbar;