import { AppBar, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, Paper, Slide, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { isLoggedIn } from '../../redux/features/auth/authService';
import SignInDialog from '../../components/common/SignInDialog';
import axios from 'axios';
import { logout, refreshAccessToken } from '../../redux/features/auth/authSlice';
import { OrganisationSessionStorageKey, OrganisationTableParameterStorageService } from '../../services/sessionStorage';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import colorConfigs from '../../configs/colorConfigs';
import OrganisationsTable from '../../components/common/tables/OrganisationsTable';
import OrganisationFilterBar from '../../components/common/cards/OrganisationFilterBar';
import OrganisationDetailCard from '../../components/common/cards/OrganisationDetailCard';

const loginpage = "/login"

axios.defaults.baseURL = window.env.API_URL;



type Props = {}


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const OrganisationsPage = (props: Props) => {

const navigate = useNavigate()
const authData = useSelector((state: RootState) => state.auth);
const [isLoading, setIsLoading] = useState<boolean>(false);
const [organisation, setOrganisation] = useState<any>(null);
const [popup, setPopup] = useState<any>("");
const [needToRefreshData, setNeedToRefreshData] = useState<any>(false);

const [sorting, setSorting] = useState<any>(() => {
  return OrganisationTableParameterStorageService.get(OrganisationSessionStorageKey.sorting) || []
});

const [columnVisibility, setColumnVisibility] = useState<any>(() => {
  return OrganisationTableParameterStorageService.get(OrganisationSessionStorageKey.columnVisibility) || {}
});

const [columnFilters, setColumnFilters] = useState<any>(() => {
  return OrganisationTableParameterStorageService.get(OrganisationSessionStorageKey.columnFilters) || []
});

const [globalFilter, setGlobalFilter] = useState<string>(() => {
  return OrganisationTableParameterStorageService.get(OrganisationSessionStorageKey.search) || "";
});

const [pagination, setPagination] = useState<any>(() => {
  return OrganisationTableParameterStorageService.get(OrganisationSessionStorageKey.pagination) || { pageSize: 1000, pageIndex: 0 }
});

const [showGlobalFilter, setShowGlobalFilter] = useState<any>(() => {
  return OrganisationTableParameterStorageService.get(OrganisationSessionStorageKey.showSearch) || false
});

const [data, setData] = useState<any>(() => {
  return OrganisationTableParameterStorageService.get(OrganisationSessionStorageKey.data) || []
});

const [count, setCount] = useState<any>(() => {
  return OrganisationTableParameterStorageService.get(OrganisationSessionStorageKey.count) || ""
});

const handleSaveButton = () => {
  setOrganisation("")
  fetchData()
  setPopup("")
}

const handleBackButton = () => {
  setOrganisation("")
  setPopup("")
}

useEffect(() => {
  if (data.length < 1) {
    // fetchData();
  } else {
    // if (!needToRefreshData) {
    //   checkForNewData();
    // }
  }
  setNeedToRefreshData(false)
}, [data]); 


const fetchData = () => {
  setIsLoading(true);
  axios.get(`organisations/`, { 
    headers: {
    'Authorization': `Bearer ${authData.access}`
  }
})
  .then(res => {
    // Set Data
    setData(res.data.results);
    console.log("organisations", res.data.results)
    OrganisationTableParameterStorageService.set(OrganisationSessionStorageKey.data, res.data.results)
    OrganisationTableParameterStorageService.set(OrganisationSessionStorageKey.count, res.data.count)
    setIsLoading(false);
    setNeedToRefreshData(false)
  })
  .catch(err => {
    console.log(err)
  })
}

const checkForNewData = () => { 
  // If new data has been added then alert refresh data button
  axios.get(`organisations?limit=1`, { 
    headers: {
    'Authorization': `Bearer ${authData.access}`
  }
})
  .then(res => {
    // Set Data
    if (count && count != res.data.count) {
      console.log("count", count, res.data.count)
      // setNeedToRefreshData(true)
      fetchData()
    }
  })
  .catch(err => {
    console.log(err)
  })
}

const handleRemoveAllFilters = () => {
  setGlobalFilter("")
  setShowGlobalFilter(false)
  setColumnFilters([])
  setColumnVisibility({})
  setPagination({ pageSize: 1000, pageIndex: 0 })
  setSorting([])
}

// AUTHENTICATION CHECK
const dispatch = useDispatch<AppDispatch>();
useEffect(() => {
  const checkAuth = async () => {
      if (!authData.isAuthenticated) {
          dispatch(logout());
          navigate('/login');
          return;
      }
      if (!isLoggedIn(authData.access)) {
          await dispatch(refreshAccessToken());
      }
  };
  
  checkAuth();
}, [dispatch, navigate, authData.isAuthenticated, authData.access]);

  return authData.isAuthenticated === false ? (
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
    ) : authData.isAuthenticated ? (
      <div style={{ height: '100%', overflowX: "hidden", maxWidth: `85vw`, minWidth: `85vw`, margin: "auto"}}>
    <div style={{ marginTop: "20px", display: "flex", height: "100%", width: "100%" }}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          // justifyContent="center"
          sx={{m:0}}
        >
          <Grid item xs={3} sx={{m:0, p:0}}>
            <Box sx={{ 
                m:0, 
                p:2, 
                height: "100%", 
                overflowY: 'auto',
                '&::-webkit-scrollbar': { display: 'none' },
                '-ms-overflow-style': 'none', // Internet Explorer 10+
                'scrollbar-width': 'none', // Firefox
                marginRight: 6, 
                display: 'flex', 
                flexDirection: 'column', 
                overflowX: "hidden", 
                maxWidth: `85vw`, 
                minWidth: `85vw`}}>
                <Box sx={{boxShadow: 5, borderRadius: 1}}>
                  <OrganisationFilterBar setData={setData} setIsLoading={setIsLoading} handleRemoveAllFilters={handleRemoveAllFilters} />
                  <Box sx={{flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'hidden', overflowX: 'scroll' }}>
                    <OrganisationsTable
                      data={data}
                      isLoading={isLoading}
                      sorting={sorting}
                      columnVisibility={columnVisibility}
                      columnFilters={columnFilters}
                      pagination={pagination}
                      globalFilter={globalFilter}
                      showGlobalFilter= {showGlobalFilter}
                      setShowGlobalFilter={setShowGlobalFilter}
                      handleRemoveAllFilters={handleRemoveAllFilters}
                      setGlobalFilter={setGlobalFilter}
                      setSorting={setSorting}
                      setColumnVisibility={setColumnVisibility}
                      setColumnFilters={setColumnFilters}
                      setPagination={setPagination}
                      setOrganisation={setOrganisation}
                      fetchData={fetchData}
                      setPopup={setPopup}
                      needToRefreshData={needToRefreshData}
                    />
                  </Box>
                </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Dialog
            open={(organisation && popup === "Edit") || popup === "Create"}
            fullScreen
            onClose={() => {setOrganisation(""); setPopup("")}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
            sx={{
              '& .MuiDialog-paper': {
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p:1, backgroundColor: colorConfigs.tables.headBg, opacity: 0.95}}>
                {popup &&
                <Typography
                      onClick={() => {setOrganisation(""); setPopup("")}}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "20px",
                        fontWeight: "bold",
                        m: "auto",
                        mt: 0,
                        mb: 0,
                        width: "40%",
                        borderRadius: 1,
                        padding: 1,
                        transition: "width 0.3s ease-in-out"
                      }}
                    >
                     {`${popup} Organisation`}
                    </Typography>  
                }
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {setOrganisation(""); setPopup("")}}
                  aria-label="close"
                  sx={{ position: 'absolute', right: 15 }}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent sx={{p:0, minWidth: "100vw", maxWidth: "100vw", backgroundColor: colorConfigs.sidebar.bg}}>
                {(popup) &&
                <OrganisationDetailCard organisation={organisation} create={popup === "Create"} handleSaveButton={handleSaveButton} handleBackButton={handleBackButton}  />
                }
            </DialogContent>
        </Dialog>
    </div>
    
) : (null);
};

export default OrganisationsPage;
