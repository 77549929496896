import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_Row, type MRT_ColumnDef, type MRT_SortingState } from 'material-react-table';
import dayjs from 'dayjs';
import { Box, Button, Grid, Link, Typography, ListItemIcon, Menu, MenuItem, MenuProps, Paper, alpha, styled } from '@mui/material';
import assets from "../../../assets";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CssBaseline from '@mui/material/CssBaseline';
import { FeedbackSessionStorageKey, FeedbackSessionStorageModel, FeedbackTableParameterStorageService } from "../../../services/sessionStorage";
import colorConfigs from "../../../configs/colorConfigs";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';

const StyledMenu = styled((props: any) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


interface FeedbackTableProps {
    data: any[];
    isLoading: boolean;
    sorting: any;
    columnVisibility: any;
    columnFilters: any;
    pagination: any;
    globalFilter: any;
    showGlobalFilter: any;
    setShowGlobalFilter: (value: any) => void;
    handleRemoveAllFilters: () => void;
    setGlobalFilter: (value: string) => void;
    setSorting: (sorting: any[]) => void;
    setColumnVisibility: (columnVisibility: { [key: string]: boolean }) => void;
    setColumnFilters: (filters: any[]) => void;
    setPagination: (pagination: { pageSize: number; pageIndex: number }) => void;
    setFeedback: (detail: any) => void;
    setPopup: (detail: any) => void;
    fetchData: () => void;
  }

const FeedbackTable: React.FC<FeedbackTableProps> = ({
  data,
  isLoading,
  sorting,
  columnVisibility,
  columnFilters,
  pagination,
  globalFilter,
  showGlobalFilter,
  setShowGlobalFilter,
  setGlobalFilter,
  handleRemoveAllFilters,
  setSorting,
  setColumnVisibility,
  setColumnFilters,
  setPagination,
  setFeedback,
  setPopup,
  fetchData
}) => {

    console.log("Feedback", data)
  
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSaveFilterClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const columns: MRT_ColumnDef<any>[] = [
        {
            accessorKey: 'id',
            header: 'ID',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Feedback<br />ID
            </div>, 
        },  
        {
            accessorFn: (row) => dayjs(row.timestamp).format('YYYY-MM-DD HH:mm:ss'),
            header: 'Date',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Date<br />&nbsp;
            </div>, 
            Cell: ({ cell }) => (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  {cell.getValue()?.toString().split(' ').join('\n')}
                </div>
              ),
        },
        {
            accessorKey: 'user_id',
            header: 'User ID',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            User<br />ID
                        </div>, 

        },
        {
            accessorKey: 'reader_feedback',
            header: "Reader Feedback",
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            Reader<br />Feedback
                        </div>,
        },
        {
            accessorKey: 'test_feedback',
            header: 'Test Feedback',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            Test<br />Feedback
                        </div>, 
        },
        {
            accessorKey: 'device_model',
            header: 'Device Model',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            Device<br />Model
                        </div>, 

        },
        {
            accessorKey: 'device_uuid',
            header: 'Device UUID',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            Device<br />UUID
                        </div>, 

        },
        {
            accessorKey: 'lot_number',
            header: 'Lot No',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            Lot<br />No
                        </div>, 

        },
        {
            accessorKey: 'organisation_id',
            header: 'Organisation ID',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            Organisation<br />ID
                        </div>, 

        },
        {
            accessorKey: 'app_version',
            header: 'App Version',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            App<br />Version
                        </div>, 

        },
        {
            accessorKey: 'os_version',
            header: 'OS Version',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            OS<br />Version
                        </div>, 

        },
        {
            accessorKey: 'result_uuid',
            header: 'Result UUID',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            Result<br />UUID
                        </div>, 

        },
        {
            accessorKey: 'test_type',
            header: 'Test Type',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            Test<br />Type
                        </div>, 

        },
    ]


    // Define the type for the keys for handling persistance of table settings in session storage
    type ResultSessionStorageKeyType = keyof FeedbackSessionStorageModel;

    // Generic handler function for persisting table parameters
    const handleParameterChange = <T,>(
        key: ResultSessionStorageKeyType,
        currentValue: T,
        setValueFunction: (value: T) => void,
        newValue: T | ((prevValue: T) => T)
        ) => {
        const updatedValue = typeof newValue === 'function' ? (newValue as (prevValue: T) => T)(currentValue) : newValue;
        FeedbackTableParameterStorageService.set(key, updatedValue);
        setValueFunction(updatedValue);
        };

    // Handlers for persisting table: sorting, pagination, visibility, filter, search, and search bar visibility
    const setSortingFunction = (newSortingValue: any) =>
        handleParameterChange(FeedbackSessionStorageKey.sorting, sorting, setSorting, newSortingValue);
    const setPaginationChangeFunction = (newPaginationValue: any) =>
        handleParameterChange(FeedbackSessionStorageKey.pagination, pagination, setPagination, newPaginationValue);
    const setColumnVisibilityFunction = (newColumnVisibilityValue: any) =>
        handleParameterChange(FeedbackSessionStorageKey.columnVisibility, columnVisibility, setColumnVisibility, newColumnVisibilityValue);
    const setFilterChangeFunction = (newFilterChangeValue: any) =>
        handleParameterChange(FeedbackSessionStorageKey.columnFilters, columnFilters, setColumnFilters, newFilterChangeValue);
    const setGlobalFilterChangeFunction = (newGlobalFilterChangeValue: string | ((prevValue: string) => string)) =>
        handleParameterChange(FeedbackSessionStorageKey.search, globalFilter, setGlobalFilter, newGlobalFilterChangeValue);
    const setShowGlobalFilterChangeFunction = (newShowGlobalFilterChangeValue: any) =>
        handleParameterChange(FeedbackSessionStorageKey.showSearch, showGlobalFilter, setShowGlobalFilter, newShowGlobalFilterChangeValue);



    return <MaterialReactTable
                columns={columns}
                data={data}
                state={{
                isLoading,
                sorting,
                columnVisibility,
                columnFilters,
                pagination,
                globalFilter,
                showGlobalFilter
                }}
                onSortingChange={setSortingFunction}
                onColumnVisibilityChange={setColumnVisibilityFunction}
                onColumnFiltersChange={setFilterChangeFunction}
                onPaginationChange={setPaginationChangeFunction}
                onGlobalFilterChange={setGlobalFilterChangeFunction}
                onShowGlobalFilterChange={setShowGlobalFilterChangeFunction}
                defaultColumn={{    
                minSize: 10,
                maxSize: 160,
                size: 10,
                }}
                positionActionsColumn="last"
                enableRowSelection
                selectAllMode="all"
                // enableRowActions
                positionPagination="top"
                enableBottomToolbar={false}
                initialState={{
                sorting,
                columnVisibility,
                columnFilters,
                pagination,
                globalFilter,
                density: 'compact',
                columnPinning: { left: ['mrt-row-select'], right: ['mrt-row-actions']},
                }}

            displayColumnDefOptions={{
                'mrt-row-actions': {
                header: "Cycles", // Renames Actions Column
                },
            }}

            muiTableContainerProps={{
                // TABLE CONTAINER
                sx: {
                    borderTopRightRadius:0,
                    borderTopLeftRadius:0,
                    height: 'auto', // Allow the table to expand naturally
                    maxHeight: 'calc(100vh - 60px)', // Sets height of the table to fix it in position              
                }
            }}

            muiTopToolbarProps={({ table }) => ({
                // TOP TOOLBAR (Export Data + filter buttons)
                sx: {
                 minWidth: "65em",
                position: 'sticky', // Pins Top Toolbar on scroll
                top: 0,
                zIndex: 1,
                overflowY: 'hidden',
                p: 0.5,
                paddingBottom: 0,
                //   color: colorConfigs.sidebar.color,
                '& .MuiToolbar-root': {
                    width: "50%",
                    padding: 0,
                    margin: 0,
                    paddingBottom: 0,
                    paddingTop: 0,
                },
                '& .MuiBox-root':{
                    p:0
                },
                '& .MuiTextField-root': {
                    p: 0,
                },
                '& .MuiTablePagination-root': {
                    margin: 0,
                    paddingTop: 0.5,
                    paddingBottom: 0.5,
                    width: "350px",
                    display: 'flex',
                    justifyContent: 'space-between'
                },
                },
            })}

            muiTableHeadProps={{
                // WHOLE TABLE HEADER
                sx: {
                position: 'sticky',
                top: 0,
                zIndex: 1,
                overflowY: 'hidden',
                
                }
            }}

            muiTableHeadCellProps={({ column }) => ({
                // TABLE HEADER CELLS
                sx: {
                border: '1px solid rgba(255, 255, 255, 0.3)',
                backgroundColor: colorConfigs.tables.headBg,
                opacity: 0.95,
                color: "white",
                paddingBottom:0.2,
                paddingTop:0.2,
                ...(column.id === 'mrt-row-select' && { // Fix Select column width
                    maxWidth: '50px',
                    width: '50px',
                }),
                '& .MuiIconButton-root': {
                    color: "white",
                },
                '& .MuiSvgIcon-root': {
                    color: "white",
                    p:0,
                    m:0,
                },
                '& .MuiTableSortLabel-root': {
                    color: 'white',
                    // p:0,
                    m:0,
                    ml:1,
                    mr: -0.5,
                    '&.Mui-active': {
                    color: 'white',
                    },
                    '& .MuiTableSortLabel-icon': {
                    color: 'white !important',
                    marginLeft: 'auto',
                    },
                },
                '& .MuiTableSortLabel-iconDirectionAsc': {
                    color: 'white !important',
                    p:0,
                    m:0,
                    ml:3
                },
                '& .MuiTableSortLabel-iconDirectionDesc': {
                    color: 'white !important',
                    p:0,
                    m:0,
                    ml:3
                },
                },
            })}
                
                
            muiFilterTextFieldProps={{
                // TABLE FILTER BUTTONS
                sx: {
                    p:0,
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    color: "white",
                    '& .MuiIconButton-root': {
                    color: "rgba(255, 255, 255, 0.5)",
                    },
                    '& .MuiInputBase-input': {
                    color: "white",
                    p: 0.5,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'none',
                    },
                    '& .MuiInput-underline:before': {
                    borderColor: 'none',
                    },
                    '&:hover .MuiInput-underline:before': {
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                    },
                    '& .MuiInput-underline:after': {
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                    },
                },
                }}

            muiTableBodyRowProps={({ row }) => ({
                // Handles Row Click  
                onClick: (event) => {
                    console.log(row.original);
                    setFeedback(row.original)
                    setPopup("Edit")
                },
                sx: {
                    cursor: 'pointer',
                    backgroundColor: row.index % 2 === 0 ? colorConfigs.tables.firstRow : colorConfigs.tables.secondRow,
                    paddingBottom:0,
                    paddingTop:0,
                },
            })}

            muiTableBodyProps={{
                // TABLE BODY
                sx: {
                maxHeight: "100%",
                overflowY: 'auto', // Allows body to scroll
                '&::-webkit-scrollbar': { display: 'none' }, // Remove scroll bar
                '-ms-overflow-style': 'none', // Internet Explorer 10+
                'scrollbar-width': 'none', // Firefox
                }
            }}

            muiTableBodyCellProps={({ cell }) => ({
                // TABLE BODY CELLS
                sx: {
                    paddingBottom:0,
                    paddingTop:0,
                    ...(cell.column.id === 'mrt-row-select' && { // Fix Select column width
                    maxWidth: '50px',
                    width: '50px',
                    }),
                },
            })}

            muiBottomToolbarProps={{
                // BOTTOM TOOLBAR (Footer - currently disabled)
            }}

            muiPaginationProps={{
                // PAGINATION TOOLBAR PROPS
                rowsPerPageOptions: [500, 1000],
                sx: {
                '& .MuiTablePagination-root': {
                },
                '& .MuiToolbar-root': {
                },
                '& .MuiTablePagination-selectLabel': {
                },
                '& .MuiTablePagination-displayedRows': {
                },
                }
            }}

            renderTopToolbarCustomActions={({ table }) => {

                const handleArchiveResults = () => {
                    table.getSelectedRowModel().flatRows.map(row => {
                        //Add archive logic
                    });
                };

                const handleExportRows = (rows: MRT_Row<any>[]) => {
                    const excel_rows: string[] = [];
                    const now = dayjs().format('YYYY-MM-DD_HH-mm-ss');
                    const filename = `${now}_feedback.csv`;
                  
                    // Column headers
                    excel_rows.push("ID,User Id,Timestamp,Reader Feedback,Test Feedback,App Version,Device Model,Device uuid,Lot Number,Organisation ID,OS Version,Resuld uuid,Test Type");
                  
                    rows.forEach(row => {
                      const rowArray = [
                        row.original.id,
                        row.original.user_id,
                        row.original.timestamp ? dayjs(row.original.timestamp).format('YYYY-MM-DD HH:mm:ss') : "",
                        row.original.reader_feedback,
                        row.original.test_feedback,
                        row.original.app_version,
                        row.original.device_model,
                        row.original.device_uuid,
                        row.original.lot_number,
                        row.original.organisation_id,
                        row.original.os_version,
                        row.original.resuld_uuid,
                        row.original.test_type,
                      ].map(field => `"${field}"`);
                      excel_rows.push(rowArray.join(','));
                    });
                  
                    // Combine all rows into a single CSV string
                    const csvContent = excel_rows.join('\n');
                  
                    // Trigger download
                    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();

                    // Check if the link has a parent node before removing it
                    if (link.parentNode) {
                        link.parentNode.removeChild(link);
                    }
                  };
                

                return (
                    <div style={{ minWidth: "30em", display: 'flex', gap: '2rem', justifyContent: "space-between", transition: showGlobalFilter ? "width 0.1s ease-in" : "width 0.5s ease-out"}}>
                    <div style={{ position: 'relative', height: 'auto', display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                      <div style={{minWidth: "30em", position: 'absolute', top: '41px', display: "flex", gap: "0.4em"}}>      
                      <Button
                        id="demo-customized-button"
                        aria-controls={open ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        onClick={handleClick}
                        endIcon={<FileDownloadOutlinedIcon />}
                        sx={{ minWidth: "7.5em", background: colorConfigs.buttons.bg, color: "white", textTransform: 'none', transition: 'transform 0.3s ease', fontWeight: "bold", '&:hover': { transform: 'scale(1.03)' } }}
                      >
                        Export Data
                      </Button>
                      <Button
                        id="demo-customized-button1"
                        variant="contained"
                        disableElevation
                        onClick={() => handleRemoveAllFilters()}
                        endIcon={<FilterAltOffOutlinedIcon />}
                        sx={{ 
                          minWidth: "5em",
                          background: colorConfigs.buttons.bg, 
                          color: "white", 
                          textTransform: 'none', 
                          transition: 'transform 0.3s ease', 
                          fontWeight: "bold", '&:hover': { transform: 'scale(1.03)' },  
                        }}
                      >
                        Clear Filters
                      </Button>
                      </div>  
                    </div>
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                      >
                        <MenuItem disabled={
                          !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        } onClick={() => handleExportRows(table.getSelectedRowModel().rows)} disableRipple>
                          Export to Excel
                        </MenuItem>
                      </StyledMenu>
                      </div>
                );
            }}
        />
}
 
export default FeedbackTable;

