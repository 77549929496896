import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import * as Yup from "yup";
import { Field, Form, Formik, FormikProvider, useFormik } from "formik";
import axios from "axios";
import { Button } from "@mui/material";

axios.defaults.baseURL = window.env.API_URL;

const PaymentCheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const authData = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const validationSchema = () => {
    return Yup.object().shape({
      tier: Yup.number()
        .required("Tier is required")
        .oneOf([1, 2, 3], "Invalid tier selected"),
    });
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      tier: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      if (!stripe || !elements) {
        console.error("Stripe has not loaded yet.");
        setLoading(false);
        return;
      }
      const payload = {
        tier: values.tier,
      };

      try {
        const response = await axios.post(
          "payments/checkout-session-for-new-subscription/",
          payload,
          {
            headers: {
              Authorization: `Bearer ${authData.access}`,
            },
          }
        );

        const session = response.data;

        // Redirect to Stripe Checkout
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });

        if (result.error) {
          console.error("ERROR:", result.error.message);
          setError(`An unexpected error occurred: ${result.error.message}`);
        }
      } catch (error: any) {
        console.error("Error during checkout:", error);
        const errorTxt = JSON.stringify(error?.response?.data);
        setError(`An unexpected error occurred: ${errorTxt || error}`);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <hr />
      <h2>Create Subscription</h2>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <label htmlFor="tier">Tier</label>
            <select
              id="tier"
              name="tier"
              onChange={formik.handleChange}
              value={formik.values.tier}
            >
              <option value="">Select a Tier</option>
              <option value="1">Tier 1</option>
              <option value="2">Tier 2</option>
              <option value="3">Tier 3</option>
            </select>
            {formik.errors.tier ? (
              <div style={{ color: "red" }}>{formik.errors.tier}</div>
            ) : null}
          </div>

          <Button
            className="form-group"
            type="submit"
            variant="contained"
            disabled={!stripe || loading}
            sx={{
              mt: 3,
              mb: 2,
              background: "#024c97",
              opacity: 0.6,
              color: "white",
              textTransform: "none",
              fontSize: 16,
            }}
          >
            {loading ? "Processing..." : "Pay"}
          </Button>
        </form>
      </FormikProvider>
      {error && <div style={{ color: "red" }}>{error}</div>}
    </>
  );
};

export default PaymentCheckoutForm;
