import React from "react";
import HomePage from "../pages/home/HomePage";
import ResultPage from "../pages/results/ResultsPage";
import { RouteType } from "./config";
import FeedbackPage from "../pages/feedback/FeedbackPage";
import UsersPage from "../pages/users/UsersPage";
import AccountPage from "../pages/account/AccountPage";
import AdminPage from "../pages/admin_panel/AdminPage";
import PaymentPage from "../pages/payments/PaymentPage";
import PaymentSuccessPage from "../pages/payments/PaymentSuccessPage";
import PaymentCancelPage from "../pages/payments/PaymentCancelPage";

const appRoutes: RouteType[] = [
    {
        index: true,
        element: <HomePage />,
        state: "home"
    },
    {
        path: "/results",
        element: <ResultPage />,
        state: "results",
    },
    {
        path: "/feedback",
        element: <FeedbackPage />,
        state: "feedback",
    },
    {
        path: "/users",
        element: <UsersPage />,
        state: "users",
    },
    {
        path: "/account",
        element: <AccountPage />,
        state: "account",
    },
    {
        path: "/admin",
        element: <AdminPage />,
        state: "admin",
    },
    {
        path: "/payment",
        element: <PaymentPage />,
        state: "payment",
    },
    {
        path: "/payment/success",
        element: <PaymentSuccessPage />,
        state: "payment-success",
    },
    {
        path: "/payment/cancel",
        element: <PaymentCancelPage />,
        state: "payment-cancel",
    },
]

export default appRoutes;