import { Box, Button, Grid, Link, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  isLoggedIn,
  refreshToken,
} from "../../redux/features/auth/authService";
import SignInDialog from "../../components/common/SignInDialog";
import axios from "axios";
import {
  logout,
  refreshAccessToken,
} from "../../redux/features/auth/authSlice";
import PaymentTestCard from "../../components/common/cards/PaymentTestCard";

const loginpage = "/login";

axios.defaults.baseURL = window.env.API_URL;

type Props = {};

const PaymentPage = (props: Props) => {
  const navigate = useNavigate();
  const authData = useSelector((state: RootState) => state.auth);

  // AUTHENTICATION CHECK
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (!authData.isAuthenticated) {
      dispatch(logout());
      navigate("/login");
      return;
    }
    if (!isLoggedIn(authData.access)) {
      dispatch(refreshAccessToken());
    }
    dispatch(refreshAccessToken());
  }, [dispatch, navigate, authData.isAuthenticated, authData.access]);

  return authData.isAuthenticated == false ? (
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
  ) : authData.isAuthenticated == true ? (
    <div style={{ height: "100%", overflowX: "auto" }}>
      <div
        style={{ height: "100%", display: "flex", alignItems: "flex-start" }}
      >
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="flex-start"
          justifyContent="center"
          style={{ height: "100%" }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <Paper
                variant="outlined"
                sx={{
                  width: "95vw",
                  overflow: "auto",
                  mx: "auto",
                  padding: 2,
                  boxShadow: 2,
                  mt: 5,
                  ml: 3,
                }}
              >
                <h1>Stripe Test Page</h1>
                <PaymentTestCard />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  ) : null;
};

export default PaymentPage;
