import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getProfile, isLoggedIn } from '../../redux/features/auth/authService';
import { useParams } from 'react-router-dom';
import { UserProfile } from '../../redux/features/auth/authSlice';
import { AuthState } from '../../redux/features/auth/authSlice';

export interface OrganisationData {
    id: string;
    name: string;
}



/**
 * Custom hook to fetch and manage clinic data based on the user type.
 * @param authData - The authData object containing authentication-related information.
 * @returns An array containing clinics.
 */
const useOrganisations = (authData: AuthState) => {
    // State to hold the fetched clinic data.
    const [organisations, setOrganisations] = useState<OrganisationData[]>([]);

    useEffect(() => {
        // Skip fetching if the user is not authenticated.
        if (!authData.isAuthenticated) {
            return;
        }

        // Asynchronous function for fetching clinic data.
        const fetchData = async () => {
            try {
                let organisationData: OrganisationData[] = [];
                if (authData.isSuperUser || authData.isAdmin) {
                    // Fetch Organisations using the 'api/organisations/' endpoint for administrators and support users.
                    const response = await axios.get('organisations/', {
                        headers: {
                            Authorization: `Bearer ${authData.access}`,
                        },
                    });

                    organisationData = (response.data.results as OrganisationData[]).map(
                        ({ id, name }) => ({
                            id,
                            name,
                        }),
                    );
                    // Update the component state with the fetched organisation data.
                    setOrganisations(organisationData);
                } else if (authData.isOrganisationAdmin || authData.isStandardUser) {
                    organisationData = [
                        {
                            id: String(authData.user?.organisation),
                            name: authData.user?.organisation_name || '',
                        },
                    ];

                    // Update the component state with the fetched organisation data.
                    setOrganisations(organisationData);
                }
                console.log('-----Organisations------');
                console.log(organisationData);
            } catch (error) {
                // Log an error message if there is an issue fetching organisation data.
                console.error('Error fetching organisation data:', error);
            }
        };

        // Call the fetchData function to initiate the effect.
        fetchData();
    }, [authData.isAuthenticated, authData.isSuperUser, authData.isAdmin, authData.isStandardUser, authData.isOrganisationAdmin]);

    return [organisations] as const;
};



const useRareOrganisations = (authData: AuthState): OrganisationData[] => {
    // State to hold the fetched clinic data.
    const [organisations, setOrganisations] = useState<OrganisationData[]>([]);

    useEffect(() => {
        // Skip fetching if the user is not authenticated.
        if (!authData.isAuthenticated) {
            return;
        }

        // Asynchronous function for fetching clinic data.
        const fetchData = async () => {
            try {
                let organisationData: OrganisationData[] = [];
                if (authData.isSuperUser || authData.isAdmin) {
                    // Fetch Organisations using the 'api/organisations/' endpoint for administrators and support users.
                    const response = await axios.get('organisations/', {
                        headers: {
                            Authorization: `Bearer ${authData.access}`,
                        },
                    });

                    organisationData = (response.data.results as OrganisationData[]).map(
                        ({ id, name }) => ({
                            id,
                            name,
                        }),
                    );
                    // Update the component state with the fetched organisation data.
                    setOrganisations(organisationData);
                } else if (authData.isOrganisationAdmin || authData.isStandardUser) {
                    organisationData = [
                        {
                            id: String(authData.user?.organisation),
                            name: authData.user?.organisation_name || '',
                        },
                    ];

                    // Update the component state with the fetched organisation data.
                    setOrganisations(organisationData);
                }
                console.log('-----Organisations------');
                console.log(organisationData);
            } catch (error) {
                // Log an error message if there is an issue fetching organisation data.
                console.error('Error fetching organisation data:', error);
            }
        };

        // Call the fetchData function to initiate the effect.
        fetchData();
    }, [authData]);

    return organisations;
};



export {
    useOrganisations,
    useRareOrganisations
};
