import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { blue, red, green, purple, yellow} from '@mui/material/colors'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      menu: 1150,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: "#6987C9"
    },
    secondary: {
      main: "#C96B69",
      muteGreen: "#69C987",
      muteYellow: "#C9A769",
      jet: '#2D2E2E',
    },
    // success: {
    //   main: "#69C987",
    // },
    // error: {
    //   main: "#C96969",
    // },
    // warning: {
    //   main: "#C9B769",
    // },
    // info: {
    //   main: "#69C987",
    // },
    background: {
      default: '#f9f9f9',
      gradient: 'linear-gradient(45deg, #E4261F 50%, #BB1B16 80%)',
      gradientCompany: 'linear-gradient(90deg, #E4261F 50%, #BB1B16 80%)',
      grey: '#919292', // Battleship Grey for backgrounds and contrasting
      silver: '#C3C4C4', // Silver for backgrounds and contrasting
      white_smoke: '#F5F5F5', // White smoke for backgrounds of components or contrasting
      off_white: '#EBEBEB', // off white for global background, less glaring that full white
      platinum: '#E0E0E1', // Plantinum, lighter silver
      whiteHeader: 'rgba(255, 255, 255, 0.3)', // White with 0.3 opacity
    },
    // transparentBackground: {
    //   whiteHeader: 'rgba(255, 255, 255, 0.3)', // White with 0.3 opacity
    //   whiteTitle: 'rgba(255, 255, 255, 0.6)', // White with 0.3 opacity
    //   subTitle: 'linear-gradient(to right, rgba(0, 0, 0, 0.025) 0%, rgba(0, 0, 0, 0.07) 30%, rgba(0, 0, 0, 0.07) 70%, rgba(0, 0, 0, 0.025) 100%);',
    //   introTitle: 'linear-gradient(to right, rgba(255, 255, 255, 0.005) 0%, rgba(255, 255, 255, 0.02) 30%, rgba(255, 255, 255, 0.02) 70%, rgba(255, 255, 255, 0.005) 100%);',
    //   whiteIntro: 'linear-gradient(to right, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.85) 30%, rgba(255, 255, 255, 0.85) 70%, rgba(255, 255, 255, 0.25) 100%);',
    //   grey: 'rgba(145, 146, 146, 0.2)', // Battleship Grey with 0.2 opacity
    //   silver: 'rgba(195, 196, 196, 0.2)', // Silver with 0.2 opacity
    //   white_smoke: 'rgba(245, 245, 245, 0.2)', // White smoke with 0.2 opacity
    //   off_white: 'rgba(235, 235, 235, 0.2)', // Off white with 0.2 opacity
    //   platinum: 'rgba(224, 224, 225, 0.2)', // Platinum with 0.2 opacity
    //   red: 'rgba(228, 38, 31, 0.85)', // Red with 0.2 opacity
    // },
    // error: {
    //   main: '#EDD463', // Yellow for error to contrast against the red of the main theme
    // },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          transition: 'transform 0.3s ease-in-out, background 0.3s ease-in-out', // Smooth transition for scaling and background
          '&:hover': {
            transform: 'scale(1.05)', // Slightly grow the button on hover
          },
          '&:active': {
          },
        },
      },
    },  
    MuiAppBar: {
      styleOverrides: {
        root: {
          // backgroundColor: "linear-gradient(45deg, #5A77B7, #7B97D4)", // Use the whiteHeader background color
          boxShadow: 'unset',
        },
      },
    },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiOutlinedInput-root': {
    //         '& fieldset': {
    //           borderColor: '#2D2E2E', // Default border color
    //           backgroundColor: "rgba(0, 0, 0, 0.05)"
    //         },
    //         '&:hover fieldset': {
    //           borderColor: '#2D2E2E', // Border color on hover
    //         },
    //         '&.Mui-focused fieldset': {
    //           borderColor: '#2D2E2E', // Border color when focused
    //         },
    //         '&.Mui-error fieldset': {
    //           borderColor: '#2D2E2E', // Border color when there is an error
    //         },
    //       },
    //       '& .MuiFormLabel-root': {
    //         '&.Mui-focused': {
    //           color: '#2D2E2E', // Label color when focused
    //         },
    //         '&.Mui-error': {
    //           color: '#2D2E2E', // Label color when there is an error
    //         },
    //       },
    //     },
    //   },
    // },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#2D2E2E', // Default border color
              backgroundColor: "rgba(0, 0, 0, 0.05)"
            },
            '&:hover fieldset': {
              borderColor: '#2D2E2E', // Border color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: '#2D2E2E', // Border color when focused
            },
            '&.Mui-error fieldset': {
              borderColor: '#2D2E2E', // Border color when there is an error
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#2D2E2E', // Label color when focused
          },
          '&.Mui-error': {
            color: '#2D2E2E', // Label color when there is an error
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            '&.Mui-focused': {
              color: '#2D2E2E', // Label color when focused
            },
            '&.Mui-error': {
              color: '#2D2E2E', // Label color when there is an error
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: '#2D2E2E', // Default border color
            backgroundColor: "rgba(0, 0, 0, 0.05)"
          },
          '&:hover fieldset': {
            borderColor: '#2D2E2E', // Border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: '#2D2E2E', // Border color when focused
          },
          '&.Mui-error fieldset': {
            borderColor: '#2D2E2E', // Border color when there is an error
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: "#C96969", // Error message color
          },
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
