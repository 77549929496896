import { Alert, AlertTitle, Snackbar, Box, Button, Card, Divider, FormControlLabel, FormGroup, Grid, IconButton, Link, Paper, Switch, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { isLoggedIn, refreshToken } from '../../../redux/features/auth/authService';
import axios from 'axios';
import { fetchUserProfile, refreshAccessToken } from '../../../redux/features/auth/authSlice';
import { Field, Form, Formik, FormikProvider, useFormik } from 'formik';
import { FormUserEmailTextField, FormUserFirstNameTextField, FormUserIDTextField, FormUserLastNameTextField, FormUserTypeTextField } from '../../../components/common/fields/ProfileFeilds';
import * as Yup from "yup";
import EditIcon from '@mui/icons-material/Edit';
import MuiAlert from '@mui/material/Alert';
import { SyntheticEvent } from 'react';
import { SnackbarCloseReason } from '@mui/material';

const loginpage = "/login"

axios.defaults.baseURL = window.env.API_URL;
type Props = {}


const OrganisationAdminCard = (props: Props) => {
const [open, setOpen] = useState(false);

const [allowEdit, setAllowEdit] = useState<boolean>(false)
const [errors, setErrors] = useState<any>(null)
const navigate = useNavigate()
const authData = useSelector((state: RootState) => state.auth);

// Handles SnackBar Alert
const handleClick = () => {
    setOpen(true);
  };
const handleSnackbarClose = (event: Event | SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
if (reason === 'clickaway') {
    return;
}
setOpen(false);
};
const handleAlertClose = (event: SyntheticEvent<Element, Event>) => {
setOpen(false);
};

// AUTHENTICATION CHECK
const dispatch = useDispatch<AppDispatch>();
useEffect(() => {
  if (!authData.isAuthenticated) {
      navigate(loginpage);
      return;
  }
  if (!isLoggedIn(authData.access)) {
      dispatch(refreshAccessToken());
  }
  console.log(authData)
}, [dispatch, navigate, authData.isAuthenticated]);


useEffect(() => {
    if (authData.user) {
        formik.setFieldValue('id', authData.user.id);
        formik.setFieldValue('first_name', authData.user.first_name);
        formik.setFieldValue('last_name', authData.user.last_name);
        formik.setFieldValue('email', authData.user.email);
        formik.setFieldValue('user_type', authData.user.user_type);
        formik.setFieldValue('last_login', authData.user.last_login);
        formik.setFieldValue('is_active', authData.user.is_active);
    }
}, [authData.user]);

const validationSchema = () => {
    return Yup.object().shape({
        first_name: Yup.string().required("This field is required"), 
        last_name: Yup.string().required("This field is required"),
        email: Yup.string().required("This field is required").email(),
    });
}

const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
        id: 0,
        first_name: "",
        last_name: "",
        email: "",
        user_type: "",
        last_login: "",
        is_active: false
    },
    validationSchema,
    onSubmit: async (values) => {
        handleClick()
        const patch_data = {
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
            }

        try {
            await axios.patch(`profile/`, patch_data, {
                headers: {
                    'Authorization': `Bearer ${authData.access}`
                }
            })
            setErrors(null);
            setAllowEdit(!allowEdit)
            dispatch(fetchUserProfile());
            console.log(`Profile edited successfully.`);
        } catch (error: any) {
            if (error?.response?.status === 400) {
                const firstErrorItem = Object.keys(error.response.data)[0];
                setErrors(`${firstErrorItem}: ${error.response.data[firstErrorItem]}`);
            } else {
                const errorText = `Unknown error 'updating' profile`;
                setErrors(errorText);
                console.error(errorText, error);
            }
            // window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        dispatch(refreshAccessToken());
    },
    });


  return (
    <Paper variant="outlined" sx={{ height: '100%', padding: 2, boxShadow: 2 }}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>
                Organisation Admin
            </Typography>
            <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            {errors && (
                <Alert severity="error" style={{ marginTop: '16px' }}>
                    <AlertTitle>Error</AlertTitle>
                    {errors}
                </Alert>
            )}
            <div className="form-group">
            <div style={{display: 'flex', gap: '16px'}}>
                <Field name="id" type="text" allowEdit={false} disabled={allowEdit} className="form-control" component={FormUserIDTextField} />
                <Field name="user_type" type="text" allowEdit={false} disabled={allowEdit} className="form-control" component={FormUserTypeTextField} />
            </div>
            <div style={{display: 'flex', gap: '16px'}}>
                <Field name="first_name" type="text" allowEdit={allowEdit} className="form-control" component={FormUserFirstNameTextField} />
                <Field name="last_name" type="text" allowEdit={allowEdit} className="form-control" component={FormUserLastNameTextField} /> 
            </div>
            <Field name="email" type="text" allowEdit={allowEdit} className="form-control" component={FormUserEmailTextField} />
            {/* <Field name="is_active" type="checkbox" allowEdit={false} disabled={allowEdit} component={FormIsActiveSwitch} /> */}
            </div>

            {!allowEdit ? 
                <div className="form-group" style={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton onClick={() => setAllowEdit(!allowEdit)} aria-label="edit">
                    <EditIcon />
                </IconButton>
            </div> :
            <div>
                <Divider variant="middle" flexItem sx={{paddingTop: "10px" }} />
                <div className="form-group" style={{ display: "flex", justifyContent: "space-between", paddingTop: "10px" }}>
                    <Button onClick={() => {setAllowEdit(!allowEdit)}} variant="outlined">Cancel Changes</Button>
                    <Button type="submit" variant="outlined">Save</Button>
                </div>
            </div>
            }
            </form>
            </FormikProvider>
            </Box>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
                    Organisation Admin successfully updated
                </MuiAlert>
            </Snackbar>
            </Paper>

  )
};

export default OrganisationAdminCard;

