import { AppBar, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, Paper, Slide, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { isLoggedIn } from '../../redux/features/auth/authService';
import SignInDialog from '../../components/common/SignInDialog';
import axios from 'axios';
import { logout, refreshAccessToken } from '../../redux/features/auth/authSlice';
import { ResultSessionStorageKey, ResultTableParameterStorageService } from '../../services/sessionStorage';
import ResultDetailCard from '../../components/common/cards/ResultDetailCard';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import ResultsTable from '../../components/common/tables/ResultsTable';
import colorConfigs from '../../configs/colorConfigs';
import ResultFilterBar from '../../components/common/cards/ResultFilterBar';

const loginpage = "/login"

axios.defaults.baseURL = window.env.API_URL;



type Props = {}


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const ResultsPage = (props: Props) => {

const navigate = useNavigate()
const authData = useSelector((state: RootState) => state.auth);
const [isLoading, setIsLoading] = useState<boolean>(false);
const [result, setResult] = useState<any>(null);
const [popup, setPopup] = useState<any>("");
const [needToRefreshData, setNeedToRefreshData] = useState<any>(false);

const [sorting, setSorting] = useState<any>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.sorting) || []
});

const [columnVisibility, setColumnVisibility] = useState<any>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.columnVisibility) || {}
});

const [columnFilters, setColumnFilters] = useState<any>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.columnFilters) || []
});

const [globalFilter, setGlobalFilter] = useState<string>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.search) || "";
});

const [pagination, setPagination] = useState<any>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.pagination) || { pageSize: 1000, pageIndex: 0 }
});

const [showGlobalFilter, setShowGlobalFilter] = useState<any>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.showSearch) || false
});

const [data, setData] = useState<any>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.data) || []
});

const [count, setCount] = useState<any>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.count) || ""
});

const handleSaveButton = () => {
  setResult("")
  fetchData()
  setPopup("")
}

const handleBackButton = () => {
  setResult("")
  setPopup("")
}


useEffect(() => {
  if (data.length < 1) {
    // fetchData();
  } 
  // else {
  //   if (!needToRefreshData) {
  //     checkForNewData();
  //   }
  // }
  setNeedToRefreshData(false)
}, [data.length]); 



const fetchData = () => {
  setIsLoading(true);
  axios.get(`results/`, { 
    headers: {
    'Authorization': `Bearer ${authData.access}`
  }
})
  .then(res => {
    // Set Data
    setData(res.data);
    console.log("results", res.data)
    ResultTableParameterStorageService.set(ResultSessionStorageKey.data, res.data)
    // ResultTableParameterStorageService.set(ResultSessionStorageKey.count, res.data)
    setIsLoading(false);
    setNeedToRefreshData(false)
  })
  .catch(err => {
    console.log(err)
  })
}

// const checkForNewData = () => { 
//   // If new data has been added then alert refresh data button
//   axios.get(`users?limit=1`, { 
//     headers: {
//     'Authorization': `Bearer ${authData.access}`
//   }
// })
//   .then(res => {
//     // Set Data
//     if (count && count != res.data.count) {
//       console.log("count", count, res.data.count)
//       // setNeedToRefreshData(true)
//       fetchData()
//     }
//   })
//   .catch(err => {
//     console.log(err)
//   })
// }


const handleRemoveAllFilters = () => {
  setGlobalFilter("")
  setShowGlobalFilter(false)
  setColumnFilters([])
  setColumnVisibility({})
  setPagination({ pageSize: 1000, pageIndex: 0 })
  setSorting([])
}

// AUTHENTICATION CHECK
const dispatch = useDispatch<AppDispatch>();
useEffect(() => {
  const checkAuth = async () => {
      if (!authData.isAuthenticated) {
          dispatch(logout());
          navigate('/login');
          return;
      }
      if (!isLoggedIn(authData.access)) {
          await dispatch(refreshAccessToken());
      }
  };
  checkAuth();
}, [dispatch, navigate, authData.isAuthenticated, authData.access]);

  return authData.isAuthenticated === false ? (
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
    ) : authData.isAuthenticated && authData.isFreeUser ? (
    <Navigate to="/account"/>
    ) : authData.isAuthenticated === true && !authData.isFreeUser ? (
      <div style={{ height: '100%', overflowX: "hidden", maxWidth: `94vw`, minWidth: `94vw`, margin: "auto"}}>
    <div style={{ marginTop: "20px", display: "flex", height: "100%", width: "100%" }}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          // justifyContent="center"
          sx={{m:0}}
        >
          <Grid item xs={3} sx={{m:0, p:0}}>
            <Box sx={{ 
                m:0, 
                p:2, 
                height: "100%", 
                overflowY: 'auto',
                '&::-webkit-scrollbar': { display: 'none' },
                '-ms-overflow-style': 'none', // Internet Explorer 10+
                'scrollbar-width': 'none', // Firefox
                marginRight: 6, 
                display: 'flex', 
                flexDirection: 'column', 
                overflowX: "hidden", 
                maxWidth: `94vw`, 
                minWidth: `94vw`}}>
                <Box sx={{boxShadow: 5, borderRadius: 1}}>
                <ResultFilterBar setData={setData} setIsLoading={setIsLoading} handleRemoveAllFilters={handleRemoveAllFilters} />
                <Box sx={{flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}>
                  <ResultsTable
                    data={data}
                    isLoading={isLoading}
                    sorting={sorting}
                    columnVisibility={columnVisibility}
                    columnFilters={columnFilters}
                    pagination={pagination}
                    globalFilter={globalFilter}
                    showGlobalFilter= {showGlobalFilter}
                    setShowGlobalFilter={setShowGlobalFilter}
                    handleRemoveAllFilters={handleRemoveAllFilters}
                    setGlobalFilter={setGlobalFilter}
                    setSorting={setSorting}
                    setColumnVisibility={setColumnVisibility}
                    setColumnFilters={setColumnFilters}
                    setPagination={setPagination}
                    fetchData={fetchData}
                    setPopup={setPopup}
                    setResult={setResult}
                  />
                  </Box>
                </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Dialog
            open={(result && popup === "Edit") || popup === "Create"}
            fullScreen
            onClose={() => {setResult(""); setPopup("")}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
            sx={{
              '& .MuiDialog-paper': {
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
          >
          <AppBar sx={{ position: 'relative' }}>
              <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p:1, backgroundColor: colorConfigs.tables.headBg, opacity: 0.95}}>
                {popup &&
                <Typography
                      onClick={() => {setResult(""); setPopup("")}}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "20px",
                        fontWeight: "bold",
                        m: "auto",
                        mt: 0,
                        mb: 0,
                        width: "40%",
                        borderRadius: 1,
                        padding: 1,
                        transition: "width 0.3s ease-in-out"
                      }}
                    >
                     Result {result.id}
                    </Typography>  
                }

                  <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {setResult(""); setPopup("")}}
                  aria-label="close"
                  sx={{ position: 'absolute', right: 15 }}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent sx={{p:0, minWidth: "100vw", maxWidth: "100vw", backgroundColor: colorConfigs.sidebar.bg}}>
                {(popup) &&
                <ResultDetailCard result={result} handleBackButton={handleBackButton}  />
                }
            </DialogContent>
        </Dialog>
    </div>
) : (null);
};

export default ResultsPage;

