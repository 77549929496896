import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_Row, type MRT_ColumnDef, type MRT_SortingState } from 'material-react-table';
import dayjs from 'dayjs';
import { Badge, Box, Button, Grid, IconButton, Link, ListItemIcon, Menu, MenuItem, MenuProps, Paper, Tooltip, Typography, alpha, styled } from '@mui/material';
import assets from "../../../assets";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CssBaseline from '@mui/material/CssBaseline';
import { UserSessionStorageKey, UserSessionStorageModel, UserTableParameterStorageService } from "../../../services/sessionStorage";
import RefreshIcon from '@mui/icons-material/Refresh';
import colorConfigs from "../../../configs/colorConfigs";
import { Link as RouterLink }from 'react-router-dom';
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined';
import { tsXLXS } from 'ts-xlsx-export';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';

const StyledMenu = styled((props: any) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


interface UsersTableProps {
    data: any[];
    isLoading: boolean;
    sorting: any;
    columnVisibility: any;
    columnFilters: any;
    pagination: any;
    globalFilter: any;
    showGlobalFilter: any;
    needToRefreshData: boolean;
    setShowGlobalFilter: (value: any) => void;
    handleRemoveAllFilters: () => void;
    setGlobalFilter: (value: string) => void;
    setSorting: (sorting: any[]) => void;
    setColumnVisibility: (columnVisibility: { [key: string]: boolean }) => void;
    setColumnFilters: (filters: any[]) => void;
    setPagination: (pagination: { pageSize: number; pageIndex: number }) => void;
    setUser: (detail: any) => void;
    setPopup: (detail: any) => void;
    fetchData: () => void;
  }

const UsersTable: React.FC<UsersTableProps> = ({
  data,
  isLoading,
  sorting,
  columnVisibility,
  columnFilters,
  pagination,
  globalFilter,
  showGlobalFilter,
  needToRefreshData,
  setShowGlobalFilter,
  setGlobalFilter,
  handleRemoveAllFilters,
  setSorting,
  setColumnVisibility,
  setColumnFilters,
  setPagination,
  setUser,
  setPopup,
  fetchData
}) => {

    console.log("Users", data)
  
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSaveFilterClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const columns: MRT_ColumnDef<any>[] = [
        {
            accessorKey: 'id',
            header: 'ID',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            ID<br />&nbsp;
            </div>, 
        },  
        {
            accessorKey: 'user_type',
            header: "User Type",
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            User<br />Type
                        </div>,
        },
        {
            accessorKey: 'first_name',
            header: 'First Name',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            First<br />Name
                        </div>, 
        },
        {
            accessorKey: 'last_name',
            header: 'Last Name',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            Last<br />Name
                        </div>, 

        },
        {
            accessorKey: 'email',
            header: 'Email',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Email<br />&nbsp;
            </div>, 
        },
        {
            accessorKey: 'organisation',
            header: 'Organisation',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Organisation<br />ID
            </div>, 
        },
        {
          accessorKey: 'organisation_name',
          header: 'Organisation Name',
          Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
          Organisation<br />Name
          </div>, 
      },
        {
            accessorKey: 'subscription_tier',
            header: 'Subscription Tier',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Subscription<br />Tier
            </div>, 
        },
        {
            accessorFn: (row) => dayjs(row.date_joined).format('YYYY-MM-DD HH:mm:ss'),
            header: 'Created',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Created<br />&nbsp;
            </div>, 
            Cell: ({ cell }) => (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  {cell.getValue()?.toString().split(' ').join('\n')}
                </div>
              ),
        },
        {
            accessorFn: (row) => row.last_login ?  dayjs(row.last_login).format('YYYY-MM-DD HH:mm:ss'): row.last_login,
            header: 'Last Login',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            Last<br />Login
                        </div>, 
            Cell: ({ cell }) => (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                    {cell.getValue()?.toString().split(' ').join('\n')}
                </div>
                ),
        },
        // {
        //     accessorKey: 'address',
        //     header: 'Address',
        //     Cell: ({ cell }) => (
        //         <div style={{ whiteSpace: 'pre-wrap' }}>
        //           {cell.getValue()?.toString().split(' ').join('\n')}
        //         </div>
        //       ),
        // },
        {
            accessorKey: 'is_active',
            header: 'Active',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Active<br />&nbsp;
            </div>, 
            Cell: ({ cell }) => (
                <div style={{display: "flex", justifyContent: "center"}}>
                  {cell.getValue()?.toString() == "true" ? <Typography fontSize="smaller" sx={{ paddingX:0.7, border: `1.5px solid ${colorConfigs.buttons.active}`, borderRadius: 2, color: colorConfigs.buttons.active }} >Active</Typography>: <Typography fontSize="smaller" sx={{ paddingX:0.7, border: `1.5px solid ${colorConfigs.buttons.inactive}`, borderRadius: 2, color: colorConfigs.buttons.inactive }} >Inactive</Typography>}
                </div>
              ),
        },
        // {
        //     accessorKey: 'total_dogs',
        //     header: 'No. of Dogs',
        //     Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        //                     No of<br />Dogs
        //                 </div>, 
        // },    
        // {
        //     accessorKey: 'total_results',
        //     header: 'No of Results',
        //     Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        //                     No. of<br />Results
        //                 </div>, 
        // },
    ]


    // Define the type for the keys for handling persistance of table settings in session storage
    type UserSessionStorageKeyType = keyof UserSessionStorageModel;

    // Generic handler function for persisting table parameters
    const handleParameterChange = <T,>(
        key: UserSessionStorageKeyType,
        currentValue: T,
        setValueFunction: (value: T) => void,
        newValue: T | ((prevValue: T) => T)
        ) => {
        const updatedValue = typeof newValue === 'function' ? (newValue as (prevValue: T) => T)(currentValue) : newValue;
        UserTableParameterStorageService.set(key, updatedValue);
        setValueFunction(updatedValue);
        };

    // Handlers for persisting table: sorting, pagination, visibility, filter, search, and search bar visibility
    const setSortingFunction = (newSortingValue: any) =>
        handleParameterChange(UserSessionStorageKey.sorting, sorting, setSorting, newSortingValue);
    const setPaginationChangeFunction = (newPaginationValue: any) =>
        handleParameterChange(UserSessionStorageKey.pagination, pagination, setPagination, newPaginationValue);
    const setColumnVisibilityFunction = (newColumnVisibilityValue: any) =>
        handleParameterChange(UserSessionStorageKey.columnVisibility, columnVisibility, setColumnVisibility, newColumnVisibilityValue);
    const setFilterChangeFunction = (newFilterChangeValue: any) =>
        handleParameterChange(UserSessionStorageKey.columnFilters, columnFilters, setColumnFilters, newFilterChangeValue);
    const setGlobalFilterChangeFunction = (newGlobalFilterChangeValue: string | ((prevValue: string) => string)) =>
        handleParameterChange(UserSessionStorageKey.search, globalFilter, setGlobalFilter, newGlobalFilterChangeValue);
    const setShowGlobalFilterChangeFunction = (newShowGlobalFilterChangeValue: any) =>
        handleParameterChange(UserSessionStorageKey.showSearch, showGlobalFilter, setShowGlobalFilter, newShowGlobalFilterChangeValue);


    return <MaterialReactTable
            columns={columns}
            data={data}
            state={{
              isLoading,
              sorting,
              columnVisibility,
              columnFilters,
              pagination,
              globalFilter,
              showGlobalFilter
            }}
            onSortingChange={setSortingFunction}
            onColumnVisibilityChange={setColumnVisibilityFunction}
            onColumnFiltersChange={setFilterChangeFunction}
            onPaginationChange={setPaginationChangeFunction}
            onGlobalFilterChange={setGlobalFilterChangeFunction}
            onShowGlobalFilterChange={setShowGlobalFilterChangeFunction}
            defaultColumn={{    
              minSize: 10,
              maxSize: 160,
              size: 10,
            }}
            positionActionsColumn="last"
            enableRowSelection
            selectAllMode="all"
            // enableRowActions
            positionPagination="top"
            enableBottomToolbar={false}
            initialState={{
              sorting,
              columnVisibility,
              columnFilters,
              pagination,
              globalFilter,
              density: 'compact',
              columnPinning: { left: ['mrt-row-select'], right: ['mrt-row-actions']},
            }}

          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: "Cycles", // Renames Actions Column
            },
          }}

          muiTableContainerProps={{
            // TABLE CONTAINER
              sx: {
                borderTopRightRadius:0,
                borderTopLeftRadius:0,
                height: 'auto', // Allow the table to expand naturally
                maxHeight: 'calc(100vh - 60px)', // Sets height of the table to fix it in position              
              }
          }}

          muiTopToolbarProps={({ table }) => ({
            // TOP TOOLBAR (Export Data + filter buttons)
            sx: {
              minWidth: "65em",
              position: 'sticky', // Pins Top Toolbar on scroll
              top: 0,
              zIndex: 1,
              overflowY: 'hidden',
              p: 0.5,
              paddingBottom: 0,
            //   color: colorConfigs.sidebar.color,
              '& .MuiToolbar-root': {
                width: "50%",
                padding: 0,
                margin: 0,
                paddingBottom: 0,
                paddingTop: 0,
              },
              '& .MuiBox-root':{
                p:0
              },
              '& .MuiTextField-root': {
                p: 0,
              },
              '& .MuiTablePagination-root': {
                margin: 0,
                paddingTop: 0.5,
                paddingBottom: 0.5,
                width: "350px",
                display: 'flex',
                justifyContent: 'space-between'
              },
            },
          })}

          muiTableHeadProps={{
            // WHOLE TABLE HEADER
            sx: {
              position: 'sticky',
              top: 0,
              zIndex: 1,
              overflowY: 'hidden',
              
            }
          }}

          muiTableHeadCellProps={({ column }) => ({
            // TABLE HEADER CELLS
            sx: {
              border: '1px solid rgba(255, 255, 255, 0.3)',
              backgroundColor: colorConfigs.tables.headBg,
              opacity: 0.95,
              color: "white",
              paddingBottom:0.2,
              paddingTop:0.2,
              ...(column.id === 'mrt-row-select' && { // Fix Select column width
                maxWidth: '50px',
                width: '50px',
              }),
              '& .MuiIconButton-root': {
                color: "white",
              },
              '& .MuiSvgIcon-root': {
                color: "white",
                p:0,
                m:0,
              },
              '& .MuiTableSortLabel-root': {
                color: 'white',
                // p:0,
                m:0,
                ml:1,
                mr: -0.5,
                '&.Mui-active': {
                  color: 'white',
                },
                '& .MuiTableSortLabel-icon': {
                  color: 'white !important',
                  marginLeft: 'auto',
                },
              },
              '& .MuiTableSortLabel-iconDirectionAsc': {
                color: 'white !important',
                p:0,
                m:0,
                ml:3
              },
              '& .MuiTableSortLabel-iconDirectionDesc': {
                color: 'white !important',
                p:0,
                m:0,
                ml:3
              },
            },
          })}
              
              
          muiFilterTextFieldProps={{
              // TABLE FILTER BUTTONS
              sx: {
                p:0,
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                color: "white",
                '& .MuiIconButton-root': {
                  color: "rgba(255, 255, 255, 0.5)",
                },
                '& .MuiInputBase-input': {
                  color: "white",
                  p: 0.5,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'none',
                },
                '& .MuiInput-underline:before': {
                  borderColor: 'none',
                },
                '&:hover .MuiInput-underline:before': {
                  borderColor: 'rgba(255, 255, 255, 0.5)',
                },
                '& .MuiInput-underline:after': {
                  borderColor: 'rgba(255, 255, 255, 0.5)',
                },
              },
            }}

          muiTableBodyRowProps={({ row }) => ({
              // Handles Row Click  
              onClick: (event) => {
                  console.log(row.original);
                  setUser(row.original)
                  setPopup("Edit")
              },
              sx: {
                  cursor: 'pointer',
                  backgroundColor: row.index % 2 === 0 ? colorConfigs.tables.firstRow : colorConfigs.tables.secondRow,
                  paddingBottom:0,
                  paddingTop:0,
              },
          })}

          muiTableBodyProps={{
            // TABLE BODY
            sx: {
              maxHeight: "100%",
              overflowY: 'auto', // Allows body to scroll
              '&::-webkit-scrollbar': { display: 'none' }, // Remove scroll bar
              '-ms-overflow-style': 'none', // Internet Explorer 10+
              'scrollbar-width': 'none', // Firefox
            }
          }}

          muiTableBodyCellProps={({ cell }) => ({
            // TABLE BODY CELLS
            sx: {
                paddingBottom:0,
                paddingTop:0,
                ...(cell.column.id === 'mrt-row-select' && { // Fix Select column width
                  maxWidth: '50px',
                  width: '50px',
                }),
            },
          })}

          muiBottomToolbarProps={{
              // BOTTOM TOOLBAR (Footer - currently disabled)
          }}

          muiPaginationProps={{
            // PAGINATION TOOLBAR PROPS
            rowsPerPageOptions: [500, 1000],
            sx: {
              '& .MuiTablePagination-root': {
              },
              '& .MuiToolbar-root': {
              },
              '& .MuiTablePagination-selectLabel': {
              },
              '& .MuiTablePagination-displayedRows': {
              },
            }
          }}

            

          renderTopToolbarCustomActions={({ table }) => {

            const handleArchiveCycles = () => {
                table.getSelectedRowModel().flatRows.map(row => {
                    //Add archive logic
                });
            };

            const handleExportRows = (rows: any[]) => {
              const excelRows = [];
              const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
              const filename = `${now}_UserList`;
            
              for (let x = 0; x < rows.length; x++) {
                const user = rows[x].original;
                const formattedDateJoined = dayjs(user.date_joined).format('YYYY-MM-DD HH:mm:ss');
                const formattedLastLogin = user.last_login ? dayjs(user.last_login).format('YYYY-MM-DD HH:mm:ss') : '';
                
                excelRows[x] = {
                  "ID": user.id,
                  "User Type": user.user_type,
                  "First Name": user.first_name,
                  "Last Name": user.last_name,
                  "Email": user.email,
                  "Organisation ID": user.organistation || '',
                  "Organisation Name": user.organistation_name || '',
                  "Subscription Tier": user.subscription_tier || '',
                  "Date Created": formattedDateJoined,
                  "Last Login": formattedLastLogin,
                  "Active": user.is_active ? "Yes" : "No",
                  // "Units": user.units,
                  // "Username": user.username || '',
                  // "Locale": user.locale || '',
                  // "Total Active Dogs": user.total_active_dogs,
                  // "Total Dogs": user.total_dogs,
                  // "Total Cycles": user.total_cycles,
                  // "Total Results": user.total_results,
                };
              }
            
              tsXLXS().exportAsExcelFile(excelRows).saveAsExcelFile(filename);
            };
                

                return (
                <div style={{minWidth: "30em", display: 'flex', gap: '2rem', justifyContent: "space-between", transition: showGlobalFilter ? "width 0.1s ease-in" : "width 0.5s ease-out"}}>
                  <div style={{ position: 'relative', height: 'auto', display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                    <Button
                      fullWidth
                      onClick={() => setPopup("Create")}
                      variant="contained"
                      id="demo-customized-button3"
                      disableElevation
                      endIcon={<GroupAddOutlinedIcon />}
                      sx={{ 
                        background: colorConfigs.buttons.bg, 
                        color: 'white', 
                        minWidth: "19.85em",
                        width: "fit-content",
                        height: "fit-content",
                        textTransform: 'none', 
                        transition: 'transform 0.3s ease', 
                        fontWeight: 'bold', 
                        '&:hover': { transform: 'scale(1.01)' } 
                      }}                
                    >
                      Create User
                    </Button>
                    <div style={{minWidth: "30em", position: 'absolute', top: '41px', display: "flex", gap: "0.4em"}}>      
                    <Button
                      id="demo-customized-button"
                      aria-controls={open ? 'demo-customized-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      variant="contained"
                      disableElevation
                      onClick={handleClick}
                      endIcon={<FileDownloadOutlinedIcon />}
                      sx={{ minWidth: "7.5em", background: colorConfigs.buttons.bg, color: "white", textTransform: 'none', transition: 'transform 0.3s ease', fontWeight: "bold", '&:hover': { transform: 'scale(1.03)' } }}
                    >
                      Export Data
                    </Button>
                    <Button
                        id="demo-customized-button1"
                        variant="contained"
                        disableElevation
                        onClick={() => handleRemoveAllFilters()}
                        endIcon={<FilterAltOffOutlinedIcon />}
                        sx={{ 
                          minWidth: "5em",
                          background: colorConfigs.buttons.bg, 
                          color: "white", 
                          textTransform: 'none', 
                          transition: 'transform 0.3s ease', 
                          fontWeight: "bold", '&:hover': { transform: 'scale(1.03)' },  
                        }}
                      >
                        Clear Filters
                      </Button>
                    </div>  
                  </div>
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem disabled={
                        !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                      } onClick={() => handleExportRows(table.getSelectedRowModel().rows)} disableRipple>
                        Export to Excel
                      </MenuItem>
                    </StyledMenu>
                    </div>
                );
            }}
            
            renderRowActions={({ row, table }) => (

                <Box sx={{ gap: '0.5rem', display: "flex" }}>
                {/* <Tooltip     
                    arrow
                    placement="left"
                    title="Cycles"
                    > */}
                    <Badge
                    badgeContent={row.original.total_cycles}
                    color={row.original.total_cycles ? 'primary' : 'default'}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    sx={{
                    '& .MuiBadge-badge': {
                        top: '13px',
                        right: '10px',
                        background: colorConfigs.tables.headBg,
                        opacity: 0.98
                    },
                    }}
                    overlap="circular"
                    >
                    <IconButton
                        disabled={!row.original.total_cycles}
                        component={RouterLink}
                        to={`/cycles?user_id=${row.original.id}`}
                        state={{ clinicID: row.original.id }}
                        sx={{transition: 'transform 0.3s ease', '&:hover': { transform: 'scale(1.05)'}}}
                    >
                        <RotateRightOutlinedIcon fontSize="large" sx={{color: row.original.total_cycles ? colorConfigs.sidebar.color: colorConfigs.sidebar.bg}} />
                    </IconButton>
                    </Badge>
                {/* </Tooltip> */}
                </Box>
              )}
        />
}
 
export default UsersTable;

