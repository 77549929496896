import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, Paper } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

const SubscriptionPage = () => {
  const authData = useSelector((state: RootState) => state.auth);
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [myTierIndex, setMyTierIndex] = useState<number>(0);

  useEffect(() => {
    fetchData();
  }, [authData.access]);

  const fetchData = async () => {
    try {
      const response = await axios.get('subscription-plans/', {
        headers: {
          Authorization: `Bearer ${authData.access}`,
        },
      });
      const updatedSubscriptions = response.data.results.map((sub:any, index:number) => {
        sub["my_tier"] = false
        if (sub.name === authData.user?.subscription_tier) {
          sub["my_tier"] = true
          setMyTierIndex(index)
        }
        return sub
      })
      setSubscriptions(updatedSubscriptions); // Adjust this if your data is in a different structure
    } catch (error) {
      console.error('Error fetching subscription plans:', error);
    }
  };

  console.log(subscriptions);


  return (
    <Paper variant="outlined" sx={{ height: '100%', width: '100%', padding: 2, boxShadow: 2 }}>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{height: "70px"}}></TableCell>
            {subscriptions.map((subscription) => (
              <TableCell
                key={subscription.id}
                align="center"
                sx={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  background: subscription.my_tier ? '#eef' : 'inherit'
                }}
              >
                {subscription.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ fontSize: '16px', fontWeight: 'bold', height: "70px" }}>Access to the App</TableCell>
            {subscriptions.map((subscription) => (
              <TableCell key={subscription.id} align="center" sx={{background: subscription.my_tier ? '#eef' : 'inherit'}}>
                {subscription.app_access ? (
                  <CheckIcon sx={{ color: 'green', fontSize: 28 }} />
                ) : (
                  <CloseIcon sx={{ color: 'red', fontSize: 28 }} />
                )}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: '16px', fontWeight: 'bold', height: "70px" }}>Access to the Portal</TableCell>
            {subscriptions.map((subscription) => (
              <TableCell key={subscription.id} align="center" sx={{background: subscription.my_tier ? '#eef' : 'inherit'}}>
                {subscription.portal_access ? (
                  <CheckIcon sx={{ color: 'green', fontSize: 28 }} />
                ) : (
                  <CloseIcon sx={{ color: 'red', fontSize: 28 }} />
                )}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: '16px', fontWeight: 'bold', height: "70px" }}>Number of users</TableCell>
            {subscriptions.map((subscription) => (
              <TableCell key={subscription.id} align="center" sx={{background: subscription.my_tier ? '#eef' : 'inherit'}}>
                <Typography variant="body2" fontWeight="bold">
                  {subscription.number_of_users === 999999 ? 'Unlimited' : subscription.number_of_users}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: '16px', fontWeight: 'bold', height: "70px" }}>Unlimited results</TableCell>
            {subscriptions.map((subscription) => (
              <TableCell key={subscription.id} align="center" sx={{background: subscription.my_tier ? '#eef' : 'inherit'}}>
                {subscription.unlimited_results ? (
                  <CheckIcon sx={{ color: 'green', fontSize: 28 }} />
                ) : (
                  <CloseIcon sx={{ color: 'red', fontSize: 28 }} />
                )}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: '16px', fontWeight: 'bold', height: "70px" }}>
              Support Level
            </TableCell>
            {subscriptions.map((subscription) => (
              <TableCell key={subscription.id} align="center" sx={{background: subscription.my_tier ? '#eef' : 'inherit'}}>
                <Typography variant="body2" fontWeight="bold">
                  {subscription.support_level === "Limited Support" ? "Enhanced Support" : subscription.support_level}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', height: "80px" }}></TableCell>
            {subscriptions.map((subscription, index) => {
              const upgradeTier = myTierIndex < index
              return (
              <TableCell key={subscription.id} align="center" sx={{background: subscription.my_tier ? '#eef' : 'inherit'}}>
                {subscription.my_tier ?
                // <Typography variant="subtitle1" color="primary" sx={{fontWeight: 'bold'}} >
                //   {`Current Plan $${subscription.price_per_month}/month`}
                // </Typography>
                <Button variant="outlined" color={upgradeTier ? "secondary" : "primary"} sx={{height: "80px", width: "150px", fontWeight: 'bold'}}>
                  {`Current Plan $${subscription.price_per_month}/month`}
                  </Button>
                :
                <Button variant="contained" color={upgradeTier ? "secondary" : "primary"} sx={{height: "80px", width: "150px", fontWeight: 'bold'}}>
                  {subscription.price_per_month === '0.00' ? 'Free' : `${upgradeTier ? 'Upgrade Now' : ''} $${subscription.price_per_month}/month`}
                </Button>
                }
              </TableCell>
            )})}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </Paper>
  );
};

export default SubscriptionPage;
