// src/Success.js
import React from "react";

function PaymentSuccessPage() {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Payment Successful!</h1>
      <p>Thank you for your purchase. Your transaction was successful.</p>
      <a href="/payment">Go back to Home</a>
    </div>
  );
}

export default PaymentSuccessPage;
